const Choice = ({
  name,
  placeholder,
  options,
  errorMsg,
  onChange,
  selectedOption,
  ...rest
}) => {
  return (
    <>
      <div
        className={`p-2  my-2 bg-gray-100 ${
          errorMsg ? "border-red-600 border" : "border-transparent"
        }`}
      >
        <div className="pb-2">{placeholder}</div>
        <div className="flex ms-4 flex-col mb-4">
          {options.map((key) => {
            return (
              <div
                className="flex flex-row items-center py-2"
                key={Math.random()}
              >
                <input
                  type="radio"
                  id={key}
                  name={name}
                  value={key}
                  defaultChecked={selectedOption === key}
                  className="border-none bg-gray-300 focus:bg-purple-primary focus:ring-transparent text-purple-primary"
                  onChange={onChange}
                />
                <label htmlFor={key}>
                  <span className="ps-2 cursor-pointer">{key}</span>
                </label>
              </div>
            );
          })}
        </div>
      </div>
      {errorMsg && <div className="text-red-600 text-sm">{errorMsg}</div>}
    </>
  );
};

export default Choice;
