import { useSelector } from "react-redux";
import { getTitle } from "../../../reducers/DonationReducer";
import Step from "./Step";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Stepper = (props) => {
  const { steps, loading } = props;
  const title = useSelector(getTitle);

  return (
    <div className="Stepper w-full md:w-4/5 lg:w-4/5 flex flex-col justify-center items-center">
      <h2 className="Stepper-header text-2xl lg:font-black font-bold pb-2">
        {loading ? (
          <Skeleton containerTestId="stepper-header-skeleton" width={200} />
        ) : (
          title
        )}
      </h2>
      <div className="Stepper-steps py-4 flex flex-row justify-between w-4/5 relative">
        {steps.map((step, index) => {
          return (
            <Step
              loading={loading}
              step={step}
              index={index}
              key={Math.random()}
            />
          );
        })}
        <div className="Stepper-line absolute h-0.5 w-11/12 bg-gray-400 top-10"></div>
      </div>
    </div>
  );
};

export default Stepper;
