import { forwardRef } from "react";
import CurrencyAmount from "../Helpers/CurrrencyAmount";
import { useSelector } from "react-redux";
import { useTranslations } from "next-intl";

import {
  getCurrency,
  getDonationAmount,
} from "../../../reducers/DonationReducer";
import { GA4_BUTTON_CLICK } from "../../TrackingScripts/helpers";
import analytics from "../../../lib/analytics";

const ga4DonateClickTracking = ({ id, text, pageType }) => {
  analytics.track(GA4_BUTTON_CLICK, {
    button_id: id,
    button_text: text,
    action_page_type: pageType,
  });
};

const DonateButton = forwardRef((props, ref) => {
  const {
    submitting,
    name,
    recurring,
    weekly,
    disabled,
    onClick,
    classes,
    pageType,
  } = props;
  const amount = useSelector(getDonationAmount);
  const currency = useSelector(getCurrency);
  const currAmount = CurrencyAmount({ amount, currency });

  const t = useTranslations("default");

  function getButtonLabel() {
    if (recurring) {
      if (weekly)
        return t("button_donate_weekly", {
          amount: currAmount,
        });
      else
        return t("button_donate_monthly", {
          amount: currAmount,
        });
    } else {
      return t("button_donate_once", {
        amount: currAmount,
      });
    }
  }

  const buttonClasses = `w-full my-2 bg-purple-primary h-16 rounded-md text-white focus:outline-none text-md md:text-lg flex items-center justify-center disabled:bg-gray-200 ${classes}`;
  return (
    <button
      ref={ref}
      className={buttonClasses}
      disabled={disabled || submitting}
      onClick={() => {
        onClick();
        ga4DonateClickTracking({
          id: name,
          text: getButtonLabel(),
          pageType,
        });
      }}
      id={name}
      name={name}
    >
      <img
        className="me-1"
        src="/assets/lock.svg"
        alt="checked"
        width={16}
        height={16}
      />
      <span> &nbsp;</span>
      <span className="priFont-extraBold uppercase tracking-tighter md:tracking-normal">
        {getButtonLabel()}
      </span>
    </button>
  );
});

DonateButton.displayName = "DonateButton";

export default DonateButton;
