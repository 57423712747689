import Input from "../../Internal/Input";

export const InputField = ({
  id,
  name,
  placeholder,
  required,
  type,
  value,
  hide,
  errorMsg,
  onChange,
  pattern,
  title,
}) => {
  return (
    <>
      <Input
        id={id}
        key={name}
        name={name}
        placeholder={placeholder}
        required={required}
        type={type}
        value={value}
        hideField={hide}
        onChange={onChange}
        errorMsg={errorMsg}
        pattern={pattern}
        title={title}
      />
    </>
  );
};
