import React, { useRef, useState } from "react";
import Select from "react-select";

const purpleColor = "#6400ff";
const whiteColor = "#fff";
const blackColor = "#000";
const grayColor = "#F3F4F6";
const transparent = "transparent";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? whiteColor : blackColor,
    background: state.isSelected || state.isFocussed ? purpleColor : whiteColor,
  }),
  control: (provided, state) => ({
    ...provided,
    background: state.isFocused || state.isSelected ? whiteColor : grayColor,
    borderRadius: 3,
    padding: "5px 8px",
    borderColor:
      state.isFocused || state.isSelected ? purpleColor : transparent,
    borderBottomWidth: "1px",
    boxShadow: state.isFocused || state.isSelected ? null : null,
    "&:hover": {
      borderColor:
        state.isFocused || state.isSelected ? purpleColor : transparent,
      borderBottomWidth: state.isSelected ? "0" : "1px",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    visibility: "hidden",
  }),
  menu: (provided) => ({
    ...provided,
    margin: 0,
    marginTop: "-2px",
    padding: 0,
    borderRadius: 3,
    fontSize: "14px",
    border: `1px solid ${purpleColor}`,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTopWidth: "0px",
    position: "relative",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
  input: (provided) => ({
    ...provided,
    outline: "none",
    outlineColor: transparent,
    border: "none",
    boxShadow: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "6px 4px",
  }),
  container: (provided) => ({
    ...provided,
    marginBottom: "0.5rem",
  }),
};

const SelectField = ({
  id,
  name,
  value,
  options,
  onChange,
  required,
  defaultValue,
  onFocus,
  onBlur,
  placeholder,
  errorMsg,
  hideField,
  ...rest
}) => {
  const selectRef = useRef(null);
  const [isFocussed, setFocus] = useState(false);
  return (
    <>
      {!hideField && (
        <div className="relative select">
          <label
            className={`absolute left-3 z-10 cursor-text pointer-events-auto transition-all	transform text-gray-400  ${
              value || selectRef?.current?.state?.value || isFocussed
                ? "scale-75 origin-top-left top-0.5"
                : "scale-100 top-1/4"
            } ${isFocussed ? "text-purple-primary" : ""} `}
            onClick={() => selectRef.current.focus()}
            htmlFor={`react-select-input-${id}`}
          >
            {placeholder}
          </label>
          <Select
            openOnFocus
            isSearchable
            menuShouldBlockScroll
            defaultValue={defaultValue}
            id={id}
            ref={selectRef}
            inputId={`react-select-input-${id}`}
            required={required}
            name={name}
            onFocus={() => {
              setFocus(true);
            }}
            onBlur={() => {
              setFocus(false);
            }}
            styles={customStyles}
            placeholder={""}
            value={value}
            onChange={onChange}
            options={options}
            className={`select ${errorMsg ? "error-border" : ""}`}
            noOptionsMessage={() => "No matches available"}
            classNamePrefix="react-select"
          ></Select>
          {errorMsg && (
            <div className="text-red-600 text-sm pb-2">{errorMsg}</div>
          )}
        </div>
      )}
    </>
  );
};

export default SelectField;
