const getWhatsappURL = (button) => {
  if (button?.title) {
    const content = button.title.replace("{LINK}", button.url);
    const url = `https://api.whatsapp.com/send?text=${content}`;
    return url;
  }
  return `https://api.whatsapp.com/send?text=${button.url}`;
};

export default getWhatsappURL;
