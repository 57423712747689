import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getShowCookiePreferenceBanner,
  setAcceptAll,
  setRejectOthers,
  setShowCookiePreferenceBanner,
} from "../../../reducers/CookiePreferenceReducer";
import { selectIsMobile } from "../../../reducers/PageReducer";
import { useTranslations } from "next-intl";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const CookiePreferences = () => {
  const dispatch = useDispatch();
  const showCookiePrefBanner = useSelector(getShowCookiePreferenceBanner);
  const isMobile = useSelector(selectIsMobile);
  const isCookieFeatureOn = useFeatureIsOn("cookie-preferences");
  const t = useTranslations("default");

  return (
    <>
      {isCookieFeatureOn && (
        <>
          {showCookiePrefBanner ? (
            <div
              data-testid="cookie-preference-modal"
              className="transition-transform 2xl:mx-auto mx-2 my-0 max-w-screen-desktop fixed p-2 inset-x-0 top-2 lg:z-50 z-high bg-white rounded-2xl border border-solid"
            >
              <div className="flex lg:flex-row flex-col lg:items-center items-start justify-between">
                <div className="lg:flex-row flex-col flex lg:items-center items-start">
                  <h2
                    className="priFont-semiBold text-lg"
                    aria-label={t("banner_cookie_settings")}
                    data-testid="cookie-preference-title"
                  >
                    🍪
                    {isMobile ? (
                      <span>&nbsp;{t("banner_cookie_settings")}&nbsp;</span>
                    ) : (
                      <>&nbsp;</>
                    )}
                  </h2>
                  <p
                    className="lg:py-0 py-2 lg:px-2 px-0 text-md"
                    data-testid="cookie-preference-message"
                    aria-label={t("banner_cookie_consent_message")}
                  >
                    <span>{t("banner_cookie_consent_message")}</span>&nbsp;
                    <a
                      href={t("footer_privacy_policy_url")}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="pe-6 text-md priFont-semiBold underline"
                      data-testid="cookie-preference-privacy-link"
                    >
                      {t("footer_privacy_policy")}
                    </a>
                  </p>
                </div>
                <div className="flex flex-row">
                  <button
                    data-testid="cookie-preference-reject-others"
                    className="bg-gray-200 p-2 text-black  rounded-md priFont-medium"
                    onClick={() => dispatch(setRejectOthers(true))}
                    aria-label={t("banner_reject_unneccessary")}
                  >
                    {t("banner_reject_unneccessary")}
                  </button>
                  <button
                    data-testid="cookie-preference-accept-all"
                    className="bg-mint-primary p-2 text-black rounded-md priFont-semiBold mx-2"
                    onClick={() => dispatch(setAcceptAll(true))}
                    aria-label={t("banner_accept_all")}
                  >
                    {t("banner_accept_all")}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="lg:hidden flex fixed top-65p right-1 rounded-2xl text-2xl justify-center items-center bg-white border z-20 border-solid"
              id="cookie-preference-icon"
              data-testid="cookie-preference-icon"
              onClick={() => dispatch(setShowCookiePreferenceBanner(true))}
            >
              🍪
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CookiePreferences;
