import React, { memo } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { selectIsMobile } from "../../../reducers/PageReducer";
import MobileLogo from "../../Internal/MobileLogo";
import HeroImage from "../../Internal/HeroImage";
import CampaignTitle from "../../Internal/CampaignTitle";
import BodyContent from "../../Internal/BodyContent";
import SourceList from "../../Internal/SourceList";
import Thermometer from "../../Internal/Thermometer";

const PageContent = ({
  imagePath,
  title,
  content,
  sources,
  language,
  achieved,
  active,
}) => {
  const isMobile = useSelector(selectIsMobile);
  return (
    <>
      <MobileLogo />
      <HeroImage path={imagePath} title={title} />
      <CampaignTitle title={title} />
      {isMobile && (
        <Thermometer
          achieved={achieved}
          classes="lg:hidden py-4 lg:px-3 px-2"
          lang={language}
          active={active}
        />
      )}
      <BodyContent content={content} />
      {sources.length > 0 && (
        <SourceList sourceList={sources} lang={language} />
      )}
    </>
  );
};

PageContent.propTypes = {
  imagePath: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  sources: PropTypes.array,
  language: PropTypes.string.isRequired,
};

PageContent.defaultProps = {
  title: "",
  content: "",
  language: "en",
};

export default memo(PageContent);
