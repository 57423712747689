export function getFirstName(fullName) {
  if (!fullName) return " ";
  let fullNameArray = fullName.split(" ");
  if (fullNameArray.length === 1) return fullName;
  if (fullNameArray.length === 2) {
    return fullNameArray[0];
  } else {
    return fullNameArray
      .slice(0, Math.floor(fullNameArray.length / 2))
      .join(" ");
  }
}

export function getLastName(fullName) {
  if (!fullName || fullName.split(" ").length === 1) return " ";

  let fullNameArray = fullName.split(" ");

  if (fullNameArray.length === 2) {
    return fullNameArray[1];
  } else {
    return fullNameArray
      .slice(Math.floor(fullNameArray.length / 2), fullNameArray.length)
      .join(" ");
  }
}
