import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import useSWR from "swr";

const useReload = () => {
  const [buildId, setBuildId] = useState(null);
  const { error } = useSWR(
    `/_next/static/${buildId}/_buildManifest.js`,
    async (url) => {
      if (!buildId) {
        return;
      }

      const response = await fetch(url + `?${new Date().getTime()}`);
      if (!response.ok) {
        throw new Error("refresh");
      }
    },
    {
      shouldRetryOnError: false,
    }
  );

  useEffect(() => {
    const hasTargetFile = Array.from(document.querySelectorAll("script"))
      .map(({ src }) => src)
      .some((url) => url.includes("_buildManifest.js"));
    if (!hasTargetFile) {
      return;
    }

    setBuildId(
      JSON.parse(document.querySelector("#__NEXT_DATA__").textContent).buildId
    );
  }, []);

  const hasBuildId = Boolean(buildId);
  const hasError = Boolean(error);

  return {
    shouldReload: hasBuildId && hasError,
  };
};

export { useReload };
