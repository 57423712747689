import { useTranslations } from "next-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrency,
  getDonationAmount,
  getIsCustomAmount,
  selectDonationAmount,
  setCovertransactionCost,
  setIsCustomAmount,
} from "../../../reducers/DonationReducer";
import CurrencyAmount from "../Helpers/CurrrencyAmount";
import SkeletonWrapper from "../../common/SkeletonWrapper";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CustomAmount = (props) => {
  const { loading } = props;
  const dispatch = useDispatch();

  const amount = useSelector(getDonationAmount);
  const currency = useSelector(getCurrency);
  const isCustomAmount = useSelector(getIsCustomAmount);
  const t = useTranslations("default");

  function handleCustomAmountChange(value) {
    if (value && !isNaN(parseFloat(value))) {
      dispatch(setIsCustomAmount(true, value));
      dispatch(selectDonationAmount(parseFloat(value)));
      dispatch(setCovertransactionCost(false));
    } else {
      dispatch(setIsCustomAmount(false, value));
      dispatch(selectDonationAmount(null));
    }
  }

  return (
    <>
      {loading ? (
        <>
          <SkeletonWrapper className="flex justify-center items-center rounded-md my-2 mx-0.5 h-16 w-46">
            <Skeleton height={64} width={153} />
          </SkeletonWrapper>
        </>
      ) : (
        <input
          placeholder={t("other")}
          type="number"
          step="5"
          min="1"
          defaultValue={
            isCustomAmount ? CurrencyAmount({ amount, currency }) : undefined
          }
          id="DonationBands-custom-amount"
          className="DonationBands-input w-46 h-16 focus:outline-none text-center bg-gray-100 rounded-sm border-gray-300 focus:border-purple-primary focus:ring-transparent text-black border my-2 mx-0.5"
          onChange={(e) => handleCustomAmountChange(e.target.value)}
        />
      )}
    </>
  );
};

export default CustomAmount;
