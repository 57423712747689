import { useState } from "react";

const Checkbox = (props) => {
  const {
    name,
    id,
    value,
    onChange,
    placeholder,
    errorMsg,
    disabled,
    dataTestId,
  } = props;

  return (
    <div className="my-2">
      <input
        type="checkbox"
        className={`${
          errorMsg ? "border-red-600 border" : "border-none"
        } bg-gray-200 focus:outline-none h-5 w-5 text-purple-primary focus:ring-transparent`}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        checked={value}
        disabled={disabled}
      />
      <label htmlFor={id} data-testid={dataTestId}>
        <span className="ps-4 cursor-pointer">{placeholder}</span>
      </label>
      {errorMsg && <div className="text-red-600 text-sm">{errorMsg}</div>}
    </div>
  );
};

export default Checkbox;
