import { useState } from "react";
import useWindowResize from "./useWindowResize";

const breakpointMobileMin = "1023px";

const useIsMobile = (isMobileOnLoad = true) => {
  const [isMobile, setIsMobile] = useState(isMobileOnLoad);

  useWindowResize(() =>
    setIsMobile(
      window && window.matchMedia(`(max-width: ${breakpointMobileMin})`).matches
    )
  );

  return isMobile;
};

export default useIsMobile;
