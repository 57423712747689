import moment from "moment";
import "moment/locale/ar";
import "moment/locale/de";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/nl";
import "moment/locale/pt";

import { useTranslations } from "next-intl";

const SourceList = ({ sourceList, lang }) => {
  moment.locale(lang || "en");
  const t = useTranslations("default");

  return (
    <div className="px-4 py-8">
      <div className="h-0.5 w-48 bg-gray-300 rounded-sm"></div>
      <div
        className="priFont-semiBold text-lg pt-8 pb-4"
        data-testid="sourcelist-title"
      >
        {t("moreInfo")}
      </div>
      {sourceList.map((source) => {
        return (
          <div className="py-4" key={Math.random()}>
            <a
              className="text-purple-primary hover:underline"
              href={source.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {source.title}
            </a>
            <div>
              {source.source}{" "}
              {source.date &&
                source.date !== "" &&
                moment(source.date).isValid() &&
                moment(source.date).format("DD MMMM YYYY")}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SourceList;
