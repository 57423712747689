import { useTranslations } from "next-intl";

const PaymentProcessing = () => {
  const t = useTranslations("default");

  return (
    <div
      id="payment-processing"
      className="flex flex-col justify-center items-center w-full h-96 hidden"
    >
      <h1 className="text-3xl font-extrabold mb-4">
        <i className="fa fa-spin fa-cog" aria-hidden />
        <span className="ps-2">{t("processing")}</span>
      </h1>
      <h4 className="mx-10">{t("processing_doNotClose")}</h4>
    </div>
  );
};

export default PaymentProcessing;
