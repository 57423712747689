import React, { memo, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslations } from "next-intl";
import moment from "moment";
import {
  getCtaCompleted,
  setSharedOnSocialMedia,
} from "../../../reducers/ActionFormReducer";
import { setMintHeader, setCurrentView } from "../../../reducers/PageReducer";
import { getMemberName, getMemberId } from "../../../reducers/MemberReducer";
import { getAcceptAll } from "../../../reducers/CookiePreferenceReducer";
import { selectFormData } from "../../../reducers/ActionFormReducer";
import Shares from "../../Shares";
import ScrollToDonate from "../../Donation/ScrollToDonate/ScrollToDonate";
import Prompt from "../../Donation/ScrollToDonate/Prompt";
import ProgressTracker from "../../ProgressTracker";
import {
  GA4_BUTTON_CLICK,
  FB_EV_POST_ACTION_DONATE_YES,
} from "../../TrackingScripts/helpers";
import { logCustomEventToFacebook } from "../../TrackingScripts/LogPixel";
import analytics from "../../../lib/analytics";
import { trackConversions } from "../../../api";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const Scroll = ({
  scrollToDonate,
  shareButtons,
  language,
  title,
  postActionAsk,
}) => {
  const t = useTranslations("default");
  const dispatch = useDispatch();
  const promptRef = useRef();
  const acceptRef = useRef();
  const declineRef = useRef();
  const signed = useSelector(getCtaCompleted);
  const currentYear = moment().year();
  const name = useSelector(getMemberName);
  const isTrackingCookieAccepted = useSelector(getAcceptAll);
  const progressTrackerClasses = "lg:hidden md:hidden justify-center";
  const { askButtonNo, askButtonYes, askMessage, askTitle } =
    postActionAsk || {};
  const formData = useSelector(selectFormData);
  const [showPrompt, setShowPrompt] = useState(false);

  const isFeatureOn = useFeatureIsOn("single-step-post-action-donate");

  const PromptScreen = (props) => {
    const {
      title,
      acceptMessage,
      declineMessage,
      ctaMessage,
      askMessage,
      handleAccept,
      handleDecline,
      hasCustomMessage,
      id,
      children,
    } = props;
    return (
      <>
        <Prompt
          title={title}
          ref={promptRef}
          acceptMessage={acceptMessage}
          declineMessage={declineMessage}
          ctaMessage={ctaMessage}
          askMessage={askMessage}
          handleAccept={handleAccept}
          handleDecline={handleDecline}
          hasCustomMessage={hasCustomMessage}
          id={id}
        >
          {children}
        </Prompt>
      </>
    );
  };

  useEffect(() => {
    let isMounted = true;
    if (signed && isMounted && !showPrompt) {
      if (isFeatureOn && acceptRef?.current !== null) {
        acceptRef.current.classList.remove("hidden");
        window.scrollTo({
          top: acceptRef.current.offsetTop - 100, // Reduce the height for header so that content doesn't get hide below the header
          behavior: "smooth",
        });
      } else if (promptRef?.current !== null) {
        setShowPrompt(true);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [signed, showPrompt]);

  const ga4Tracking = ({ target, id }) => {
    analytics.track(GA4_BUTTON_CLICK, {
      button_id: id,
      button_text: target?.innerText,
      action_page_type: "petition",
    });
  };
  const handleAcceptClick = async ({ target, id }) => {
    if (acceptRef?.current !== null) {
      acceptRef.current.classList.remove("hidden");
      acceptRef.current.scrollIntoView({ behavior: "smooth" });
      dispatch(setMintHeader(false));
      ga4Tracking({ target, id });
      if (id === "donate-two-step-accept") {
        dispatch(setCurrentView("postActionDonate"));
        const { country, email, name } = formData || {};
        const payload = { country, email, name, user_id: getMemberId() };
        logCustomEventToFacebook(FB_EV_POST_ACTION_DONATE_YES, payload);
        await trackConversions(
          {
            action: FB_EV_POST_ACTION_DONATE_YES,
            payload,
          },
          isTrackingCookieAccepted
        );
      } else {
        dispatch(setCurrentView("postActionShare"));
      }
    }
  };

  const handleDeclineClick = ({ target, id }) => {
    if (declineRef?.current !== null) {
      declineRef.current.classList.remove("hidden");
      declineRef.current.scrollIntoView({ behavior: "smooth" });
      ga4Tracking({ target, id });
    }
  };

  return (
    <>
      {scrollToDonate ? (
        <>
          {!isFeatureOn && (
            <PromptScreen
              title={askTitle || t("share_thanks", { title })}
              acceptMessage={askButtonYes || t("share_accept")}
              declineMessage={askButtonNo || t("share_decline")}
              ctaMessage={t("donate_cta")}
              askMessage={askMessage || t("donate_ask", { name, currentYear })}
              handleAccept={handleAcceptClick}
              handleDecline={handleDeclineClick}
              hasCustomMessage={askMessage ? true : false}
              id={"donate"}
            />
          )}
          <ScrollToDonate ref={acceptRef} />
          <Shares
            buttons={shareButtons}
            title={title}
            lang={language}
            ref={declineRef}
          />
        </>
      ) : (
        <>
          <PromptScreen
            title={askTitle || t("share_thanks", { title })}
            acceptMessage={t("share_accept")}
            declineMessage={t("share_decline")}
            ctaMessage={t("share_cta")}
            handleAccept={handleAcceptClick}
            handleDecline={() => {
              dispatch(
                setSharedOnSocialMedia({ opted: false, completed: true })
              );
              handleDeclineClick();
            }}
            hasCustomMessage={false}
            id={"share"}
          >
            <ProgressTracker
              dataTestId="share-ask-progress-tracker"
              classes={progressTrackerClasses}
              showLogo={true}
            />
          </PromptScreen>
          <Shares
            buttons={shareButtons}
            title={title}
            lang={language}
            ref={acceptRef}
          />
          <ScrollToDonate ref={declineRef} />
        </>
      )}
    </>
  );
};

Scroll.propTypes = {
  scrollToDonate: PropTypes.bool.isRequired,
  shareButtons: PropTypes.array,
  language: PropTypes.string.isRequired,
  title: PropTypes.string,
  postActionAsk: PropTypes.object,
};

Scroll.defaultProps = {
  loading: true,
  language: "en",
};

export default memo(Scroll);
