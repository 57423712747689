import { forwardRef } from "react";
import { useSelector } from "react-redux";
import Donation from "..";
import { getCtaCompleted } from "../../../reducers/ActionFormReducer";

const ScrollToDonate = forwardRef((props, ref) => {
  const signed = useSelector(getCtaCompleted);
  return (
    <div
      ref={ref}
      id="donation-section"
      className="hidden min-h-screen flex justify-center items-center mt-5 lg:mt-30 lg:mb-60 mb-40"
    >
      <div className="md:w-1/2 w-full h-full">
        {signed && <Donation scrollToDonate={true} />}
      </div>
    </div>
  );
});

ScrollToDonate.displayName = "ScrollToDonate";

export default ScrollToDonate;
