const titleWrapperClasses = "my-1";

const CampaignTitle = ({ title }) => {
  return (
    <h1 className="py-6 bg-mint-primary text-black flex items-center lg:ps-8 ps-3 lg:text-4xl text-2xl priFont-extraBold lg:mb-8 mb-3">
      {title}
    </h1>
  );
};

export default CampaignTitle;
