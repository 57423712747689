import { useEffect, useState, useCallback } from "react";
import Popup from "reactjs-popup";
import DonateButton from "../DonateButton";
import MemberConsentMsg from "../MemberConsentMsg";
import { CompleteDonationMsg } from "../DonationPrintMessages";
import PaymentMethodItem from "./PaymentMethodItem";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrency,
  getDonationAmount,
  getFormId,
  getFormValues,
  getSavedPaymentMethods,
  getShowSavedPayments,
  getWeeklyDonation,
  setRecurring,
  setShowSavedPayments,
} from "../../../../reducers/DonationReducer";
import { getCountry } from "../../../../reducers/ActionFormReducer";
import { getMember } from "../../../../reducers/MemberReducer";
import { postOneClickDonation } from "../../../../api";
import { logGa4Ecommerce } from "../../../TrackingScripts/LogGA";
import {
  getFollowUpTemplateTitle,
  getPageId,
  getSupportedFollowUpTemplateTitles,
  selectOneClick,
  selectRecurringDefault,
  selectActionKitId,
} from "../../../../reducers/PageReducer";
import { handleFollowUpPage, makeCancelable } from "../../../Utils";
import { useRouter } from "next/router";
import { useTranslations } from "next-intl";
import CoverTransactionCosts from "../CoverTransactionCost";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const ExpressDonation = ({
  setPaymentError,
  showMonthlyButton,
  showOneOffButton,
  scrollToDonate,
}) => {
  const t = useTranslations("donationForm");
  const showSavedPayments = useSelector(getShowSavedPayments);
  const savedPaymentMethods = useSelector(getSavedPaymentMethods);
  const isCoverTransactionCostVisible = useFeatureIsOn(
    "cover-transaction-cost"
  );

  const [donationButtonRefVisible, setDonationButtonRefVisible] =
    useState(false);

  const dispatch = useDispatch();
  const { isReady } = useRouter();

  // Selectors to post transaction details
  const page_id = useSelector(getPageId);
  const currentFollowUpTemplate = useSelector(getFollowUpTemplateTitle);
  const supportedFollowUpTemplateTitles = useSelector(
    getSupportedFollowUpTemplateTitles
  );
  const country = useSelector(getCountry);

  const form_id = useSelector(getFormId);
  const formValues = useSelector(getFormValues);
  const savedMemberDetails = useSelector(getMember);
  const amount = useSelector(getDonationAmount);
  const currency = useSelector(getCurrency);
  const isWeeklyDonation = useSelector(getWeeklyDonation);
  const isOneClickDonation = useSelector(selectOneClick);
  const recurringDefault = useSelector(selectRecurringDefault);
  const akid = useSelector(selectActionKitId);

  const [currentPaymentMethod, setCurrentPayMethod] = useState(
    savedPaymentMethods ? savedPaymentMethods[0] : null
  );
  const [submitting, setSubmitting] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [failureReason, setFailureReason] = useState(null);
  const [optForRedonation, setOptForRedonation] = useState(false);

  const donationButtonRef = useCallback(
    (node) => {
      if (node) {
        setDonationButtonRefVisible(true);
      }
    },
    [setDonationButtonRefVisible]
  );

  useEffect(() => {
    if (submitting) {
      document.getElementById("payment-processing").classList.remove("hidden");
      document.getElementById("fundraiser-bar").classList.add("hidden");
    } else {
      document.getElementById("payment-processing").classList.add("hidden");
      document.getElementById("fundraiser-bar").classList.remove("hidden");
    }
  }, [submitting]);

  const setRecurringChoice = useCallback(
    (recurringChoice) => {
      const isRecurring = recurringChoice === "recurring";
      dispatch(setRecurring(isRecurring));
      return isRecurring;
    },
    [dispatch]
  );

  const buildPaymentPayload = useCallback(
    (recurring_choice) => {
      const user = {
        form_id,
        ...(savedMemberDetails || formValues),
        ...(country?.value && { country: country.value }),
      };

      return {
        user,
        akid,
        payment: {
          currency,
          amount: amount.toString(),
          recurring: isOneClickDonation
            ? false
            : setRecurringChoice(recurring_choice),
          payment_method_id: currentPaymentMethod.id,
        },
        ...(optForRedonation && { allow_duplicate: optForRedonation }),
      };
    },
    [
      akid,
      amount,
      country,
      currentPaymentMethod,
      currency,
      formValues,
      form_id,
      isOneClickDonation,
      optForRedonation,
      savedMemberDetails,
      setRecurringChoice,
    ]
  );

  const getPageType = useCallback(() => {
    return scrollToDonate ? "petition" : "donation";
  }, [scrollToDonate]);

  const gaTrackTransactionSubmitted = useCallback(
    ({ amount, currency, recurring }) => {
      logGa4Ecommerce({
        amount,
        currency,
        recurring,
        category: "stored_payment_method",
        pageType: getPageType(),
      });
    },
    [getPageType]
  );

  const makeOneClickDonation = useCallback(
    (recurringChoice) => {
      if (!currentPaymentMethod) return null;

      try {
        setSubmitting(true);

        const payload = buildPaymentPayload(recurringChoice);

        (async () => {
          const {
            success,
            follow_up_url,
            immediate_redonation,
            message,
            errors,
          } = await postOneClickDonation(page_id, payload);
          if (success === true) {
            gaTrackTransactionSubmitted({
              amount,
              currency,
              recurring: recurringChoice,
            });

            const { search } = window.location || {};
            const followUpUrl = follow_up_url
              ? `${follow_up_url}${search}`
              : undefined;
            handleFollowUpPage(
              followUpUrl,
              currentFollowUpTemplate,
              supportedFollowUpTemplateTitles
            );
          } else if (immediate_redonation === true) {
            setOpenPopup(true);
            setFailureReason(message);
            setOptForRedonation(true);
          } else {
            console.warn("One-Click donation error", errors);
            setPaymentError(true);
            setFailureReason(true);
            setSubmitting(false);
          }
        })();
      } catch (error) {
        setPaymentError(true);
        setFailureReason("unknown error");
        setSubmitting(false);
      }
    },
    [
      amount,
      buildPaymentPayload,
      currency,
      currentFollowUpTemplate,
      currentPaymentMethod,
      gaTrackTransactionSubmitted,
      page_id,
      setPaymentError,
      setFailureReason,
      supportedFollowUpTemplateTitles,
    ]
  );

  useEffect(() => {
    let isMounted = true;
    const makeOneClickDonationAsync = async () => {
      await makeOneClickDonation(recurringDefault);
    };

    if (
      isOneClickDonation &&
      isMounted &&
      isReady &&
      submitting === false &&
      donationButtonRefVisible &&
      failureReason === null
    ) {
      console.log("triggering one click donation");
      makeCancelable(makeOneClickDonationAsync());
    }
    return () => {
      isMounted = false;
    };
  }, [
    isOneClickDonation,
    makeOneClickDonation,
    recurringDefault,
    isReady,
    submitting,
    donationButtonRefVisible,
    failureReason,
  ]);

  const makePayment = async (recurring_choice) => {
    if (!currentPaymentMethod) return null;

    try {
      setSubmitting(true);

      const payload = buildPaymentPayload(recurring_choice);

      postOneClickDonation(page_id, payload).then((resp) => {
        setSubmitting(false);
        if (resp.success) {
          gaTrackTransactionSubmitted({
            amount,
            currency,
            recurring: recurring_choice,
          });
          handleFollowUpPage(
            resp.follow_up_url,
            currentFollowUpTemplate,
            supportedFollowUpTemplateTitles
          );
        } else if (resp.immediate_redonation) {
          setOpenPopup(true);
          setFailureReason(resp?.message);
          setOptForRedonation(true);
        } else if (resp.error) {
          console.warn("Express donation error", JSON.stringify(resp.error));
          setPaymentError(true);
          setSubmitting(false);
        }
      });
    } catch (error) {
      setSubmitting(false);
    }
  };

  function renderSingle() {
    const item = savedPaymentMethods[0];
    if (!item) return null;
    return (
      <div className="PaymentMethodWrapper">
        <div className="ExpressDonation__single-item">
          <PaymentMethodItem paymentMethod={item} />
        </div>
      </div>
    );
  }

  function renderMultiple() {
    return (
      <div className="PaymentMethodWrapper">
        <span className="ExpressDonation__prompt">
          Select a saved payment method
        </span>

        {savedPaymentMethods.map((paymentMethod) => (
          <PaymentMethodItem
            key={paymentMethod.id}
            paymentMethod={paymentMethod}
            checked={currentPaymentMethod === paymentMethod}
            onChange={() => setCurrentPayMethod(paymentMethod)}
          />
        ))}
      </div>
    );
  }

  if (savedPaymentMethods.length === 0 || !showSavedPayments) return null;

  return (
    <div className="flex flex-col">
      <div className="ExpressDonation__payment-methods">
        {savedPaymentMethods?.length === 1 ? renderSingle() : renderMultiple()}
        <div
          className="text-sm text-purple-primary cursor-pointer"
          onClick={() => dispatch(setShowSavedPayments(false))}
        >
          Add payment method
        </div>
      </div>
      <div className="h-0.5 bg-gray-200 w-full my-2"></div>
      {isCoverTransactionCostVisible && <CoverTransactionCosts />}
      <MemberConsentMsg duration="monthly" />
      <div className="mt-4">
        <CompleteDonationMsg />
        <div className="mt-4 flex flex-col items-center">
          {showOneOffButton && (
            <DonateButton
              submitting={submitting}
              name="one_time"
              classes="mb-0"
              recurring={false}
              weekly={isWeeklyDonation}
              disabled={currentPaymentMethod === null || submitting}
              pageType={getPageType()}
              onClick={() => {
                makePayment("one_time");
              }}
              theme={showMonthlyButton ? "secondary" : "primary"}
              ref={donationButtonRef}
            />
          )}
          {showMonthlyButton && (
            <DonateButton
              submitting={submitting}
              name="recurring"
              recurring={true}
              weekly={isWeeklyDonation}
              disabled={currentPaymentMethod === null || submitting}
              pageType={getPageType()}
              onClick={() => {
                makePayment("recurring");
              }}
              theme="primary"
            />
          )}
        </div>
      </div>
      <Popup
        open={openPopup}
        closeOnDocumentClick
        contentStyle={{
          width: "auto",
          padding: 30,
          position: "relative",
          background: "rgb(255, 255, 255)",
          border: "1px solid rgb(187, 187, 187)",
        }}
        onClose={() => {
          setOpenPopup(false);
        }}
      >
        <div
          className="PaymentExpressDonationConflict lg:w-600 w-auto text-center flex flex-col items-center"
          data-testid="PaymentExpressDonationConflict"
        >
          <div className="PaymentExpressDonationConflict--reason text-xl mb-8">
            {failureReason}
          </div>
          <button
            className="PaymentExpressDonationConflict--accept py-3 px-12 text-xl font-semibold max-w-5xl rounded-md text-white bg-purple-primary hover:bg-purple-hover mb-4"
            onClick={() => {
              setOpenPopup(false);
              setOptForRedonation(true);
              makePayment("one_time");
              setFailureReason(null);
            }}
          >
            {t("redonate_popup_yes")}
          </button>
          <button
            className="PaymentExpressDonationConflict--decline text-purple-primary font-semibold"
            onClick={() => {
              setOpenPopup(false);
              setOptForRedonation(false);
              setSubmitting(false);
            }}
          >
            {t("redonate_popup_no")}
          </button>
        </div>
      </Popup>
    </div>
  );
};

export default ExpressDonation;
