import { useTranslations } from "next-intl";
import { getErrorsByCode } from "../../../lib/paymentErrorsUtils";

const PaymentError = ({ errorResp }) => {
  const t = useTranslations("default");
  let errors = getErrorsByCode(errorResp?.code);

  return (
    <div className="border rounded-sm border-red-600 bg-red-100 mb-8 p-4">
      <div>
        <span className="fa fa-exclamation-triangle text-red-500 text-xl" />
        <span data-testid="payment-error" className="text-sm ps-2">
          {t("donation_errorMsg")}
        </span>
      </div>
      {errors.map((error, index) => {
        return (
          <div className="mt-4 text-sm" key={index}>
            {t(error)}
          </div>
        );
      })}
    </div>
  );
};

export default PaymentError;
