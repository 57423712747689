import { useTranslations } from "next-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  validateForm,
  getFormErrors,
  getForm,
  setFormValues,
  getFormValues,
  getFormSubmitting,
  setFormSubmitting,
  setPaymentMethods,
} from "../../../reducers/DonationReducer";
import { getMember } from "../../../reducers/MemberReducer";
import CountryList from "../../Form/Fields/CountryList";
import { InputField } from "../../Form/Fields/InputField";
import Button from "../../Internal/Button";

const MemberDetails = (props) => {
  const { ctaText } = props;

  const t = useTranslations("default");

  const dispatch = useDispatch();

  const formFields = useSelector(getForm);
  const formValues = useSelector(getFormValues);
  const formErrors = useSelector(getFormErrors);
  const member = useSelector(getMember);
  const formSubmitting = useSelector(getFormSubmitting);

  function getCTAtext() {
    if (formSubmitting) {
      return t("processing");
    } else if (ctaText) {
      return ctaText;
    } else {
      return t("submit");
    }
  }

  function fieldsToDisplay() {
    return formFields.filter((field) => {
      switch (field.display_mode) {
        case "all_members":
          return true;
        case "recognized_members_only":
          return recognizedMemberPresent();
        case "new_members_only":
          return !recognizedMemberPresent();
        default:
          console.log(
            `Unknown display_mode "${field.display_mode}" for field "${field.name}"`
          );
          return false;
      }
    });
  }

  function recognizedMemberPresent() {
    return !!member.email;
  }

  const handleSubmitForm = (event) => {
    event.preventDefault();
    dispatch(setFormSubmitting(true));
    try {
      dispatch(validateForm());
      dispatch(setPaymentMethods());
    } catch (e) {
      console.log("FAILED", e);
      dispatch(setFormSubmitting(false));
    }
  };

  const onInputFieldChange = (e) => {
    dispatch(setFormValues(e));
  };

  return (
    <div className="py-8 flex flex-col w-full h-full justify-between MemberDetailsForm-root">
      <form onSubmit={handleSubmitForm} className="action-form">
        {fieldsToDisplay().map(
          ({ id, data_type, name, label, value, required }) => {
            switch (data_type) {
              case "text":
                return (
                  <InputField
                    id={id}
                    key={name}
                    name={name}
                    placeholder={label}
                    required={required}
                    type={data_type}
                    value={formValues[name]}
                    onChange={onInputFieldChange}
                    errorMsg={
                      formErrors && formErrors[name] && formErrors[name][0]
                        ? formErrors[name][0]
                        : null
                    }
                  />
                );
              case "email":
                return (
                  <InputField
                    id={id}
                    key={name}
                    name={name}
                    placeholder={label}
                    required={required}
                    type={data_type}
                    value={formValues[name]}
                    onChange={onInputFieldChange}
                    errorMsg={
                      formErrors && formErrors[name] && formErrors[name][0]
                        ? formErrors[name][0]
                        : null
                    }
                    pattern={
                      "^[a-zA-Z0-9._%+\\-]+@[a-zA-Z0-9.\\-]+\\.[a-zA-Z]{2,}$"
                    }
                    title="Enter a valid email - username@domain.com"
                  />
                );
              case "postal":
                return (
                  <InputField
                    id={id}
                    key={name}
                    name={name}
                    placeholder={label}
                    required={required}
                    type={"text"}
                    maxLength={249}
                    value={formValues[name]}
                    onChange={onInputFieldChange}
                    errorMsg={
                      formErrors && formErrors[name] && formErrors[name][0]
                        ? formErrors[name][0]
                        : null
                    }
                  />
                );
              case "phone":
                return (
                  <InputField
                    id={id}
                    key={name}
                    name={name}
                    placeholder={label}
                    required={required}
                    type={"tel"}
                    maxLength={249}
                    value={formValues[name]}
                    onChange={onInputFieldChange}
                    errorMsg={
                      formErrors && formErrors[name] && formErrors[name][0]
                        ? formErrors[name][0]
                        : null
                    }
                  />
                );
              case "country":
                return (
                  <CountryList
                    id={id}
                    key={name}
                    label={label}
                    errorMsg={
                      formErrors && formErrors[name] && formErrors[name][0]
                        ? formErrors[name][0]
                        : null
                    }
                  />
                );
              default:
                console.log("no match", data_type);
            }
          }
        )}
        <Button
          disabled={formSubmitting}
          type="submit"
          classes="MemberDetails__proceed-button"
          dataTestId="proceed-to-payment"
        >
          <span className="text-sm lg:text-lg priFont-extraBold">
            {getCTAtext()}
          </span>
        </Button>
      </form>
    </div>
  );
};

export default MemberDetails;
