import React, { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const SkeletonWrapper = ({ className, dataTestId, children }) => {
  const classes = classNames(className);
  return (
    <div data-testid={dataTestId} className={classes}>
      {children}
    </div>
  );
};

SkeletonWrapper.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  children: PropTypes.node.isRequired,
};

SkeletonWrapper.defaultProps = {
  className: "",
  dataTestId: "skeleton-wrapper",
};

export default memo(SkeletonWrapper);
