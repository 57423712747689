const loadSp = (cb) => {
  const existingScript = document.getElementById("spScript");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = "https://c.shpg.org/99/sp.js";
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      if (cb) cb();
    };
  }
  if (existingScript && cb) cb();
};

export default loadSp;
