import { useTranslations } from "next-intl";
import { useDispatch, useSelector } from "react-redux";

import { removeSavedMemberDetails } from "../../../lib/removeSavedMemberDetails";
import { changeStep } from "../../../reducers/DonationReducer";
import { getMemberName } from "../../../reducers/MemberReducer";

const KnownUser = (props) => {
  const { loading } = props;
  const dispatch = useDispatch();

  const memberName = useSelector(getMemberName);

  const t = useTranslations("default");

  return (
    <>
      {memberName && !loading ? (
        <div
          data-testid="donation-data-auto-fill"
          className="flex flex-row items-center w-full justify-between pb-6"
        >
          <div className="flex flex-row">
            <img
              src="/assets/checked.svg"
              alt="checked"
              width={20}
              height={20}
            />
            <span
              data-testid="auto-fill-member-name"
              className="ps-2 priFont-semiBold"
            >
              {memberName}
            </span>
          </div>
          <div
            id="clear-member-data"
            className="text-sm text-purple-primary cursor-pointer"
            onClick={() => {
              dispatch(changeStep(1));
              removeSavedMemberDetails(dispatch);
            }}
          >
            {t("sign_out")}
          </div>
        </div>
      ) : null}
    </>
  );
};
export default KnownUser;
