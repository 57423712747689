import { getSignatureGoal } from "../../Utils";
import { useTranslations } from "next-intl";

const Thermometer = ({ achieved, classes, lang, active }) => {
  const goal = achieved ? getSignatureGoal(achieved) : 100;
  const percentile = (achieved / goal) * 100;
  const t = useTranslations("default");

  const formattedAchieved = new Intl.NumberFormat(lang, {
    style: "decimal",
  }).format(achieved);
  const formattedRemain = new Intl.NumberFormat(lang, {
    style: "decimal",
  }).format(goal - achieved);

  let formattedGoal;
  if (goal < 1000) {
    formattedGoal = goal;
  } else if (goal > 1000 && goal < 1000000) {
    formattedGoal = goal / 1000 + "K";
  } else if (goal > 1000000) {
    formattedGoal = goal / 1000000 + " " + t("million");
  }

  return (
    <>
      {active ? (
        <div className={classes}>
          <div className="flex flex-wrap items-center mb-4 justify-between">
            <div
              className="text-sm mb-0.5 font-black priFont-semiBold  tracking-tighter"
              data-testid="signatures-achieved"
            >
              <>{t("signatures", { achieved: formattedAchieved })}</>
            </div>
            <div
              className="text-xs mb-0.5 font-black priFont-semiBold text-gray-400 uppercase  tracking-tighter"
              data-testid="signatures-remain"
            >
              <>
                {t("signatures_until_goal", {
                  remaining: formattedRemain,
                  goal: formattedGoal,
                })}
              </>
            </div>
          </div>
          <div className="w-full flex flex-row h-2.5 my-2 border-solid rounded-lg border-gray-100 bg-gray-200 mb-4">
            <div
              className="h-2.5 rounded-lg transition-width duration-700 ease-linear bg-purple-primary"
              style={{ width: `${percentile}%` }}
              data-testid="progress-bar"
            ></div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Thermometer;
