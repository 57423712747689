import { useTranslations } from "next-intl";
import { setShowCookiePreferenceBanner } from "../../reducers/CookiePreferenceReducer";
import { useDispatch } from "react-redux";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

function Footer() {
  const org = {
    logo: "https://champaign-assets-production.s3.us-west-2.amazonaws.com/unique/Eko_Logo_White.svg",
    onClick: () => {},
    openNewTab: false,
  };
  const year = new Date().getFullYear();
  const t = useTranslations("default");
  const dispatch = useDispatch();
  const isCookieFeatureOn = useFeatureIsOn("cookie-preferences");

  const socialMediaList = [
    { icon: "fa-instagram", link: "https://www.instagram.com/eko.movement/" },
    { icon: "fa-youtube", link: "https://www.youtube.com/@Eko.Movement" },
    { icon: "fa-twitter", link: "https://twitter.com/Eko_Movement" },
    { icon: "fa-tiktok", link: "https://www.tiktok.com/@eko.movement" },
    { icon: "fa-facebook", link: "https://www.facebook.com/Eko.Movement/" },
  ];
  return (
    <div id="footer">
      <footer className="h-40 w-full bg-blue-primary lg:block hidden">
        <div className="mx-auto my-0 max-w-screen-desktop 2xl:px-0 px-8 h-full flex items-center flex-row">
          <div className="ps-0 w-full h-full flex items-center">
            <div className="flex flex-col">
              <div className="flex flex-row items-center pb-6">
                <a
                  className="cursor-pointer"
                  onClick={org.onClick}
                  href="#"
                  target={`${org.openNewTab ? "_blank" : "_self"}`}
                >
                  <img src={org.logo} alt="org-logo" width="100" height="55" />
                </a>
                <div
                  className="ps-6 text-xl text-white"
                  data-testid="footer-tagline"
                >
                  {t("branding_tagline")}
                </div>
              </div>
              <div className="flex flex-row text-white pb-4">
                <a
                  href={t("footer_home_url")}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pe-6"
                >
                  {t("footer_home")}
                </a>
                <a
                  href={t("footer_about_url")}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pe-6"
                >
                  {t("footer_about")}
                </a>
                <a
                  href={t("footer_privacy_policy_url")}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pe-6"
                >
                  {t("footer_privacy_policy")}
                </a>
                <a
                  href={t("footer_contact_url")}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="pe-6"
                >
                  {t("footer_contact")}
                </a>
                {/* // TODO Enable feature flag when cookie preference is ready to be show to users */}
                {isCookieFeatureOn && (
                  <div
                    onClick={() => {
                      dispatch(setShowCookiePreferenceBanner(true));
                    }}
                    className="pe-6 cursor-pointer"
                  >
                    {t("footer_manage_cookies")}
                  </div>
                )}
              </div>
              <div className="text-white">
                {`© ${year} Ekō, Creative Commons Attribution 3.0 Unported License.`}
              </div>
            </div>
          </div>
          <div className="h-full flex items-center">
            {socialMediaList.map((item, key) => {
              return (
                <a
                  key={key}
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-2"
                >
                  <i
                    className={`fa fa-brands ${item.icon} text-xl cursor-pointer text-white `}
                  ></i>
                </a>
              );
            })}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
