import Modal from "react-modal";
import { useEffect } from "react";

const customStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    inset: "0px",
    zIndex: "999",
    backgroundColor: "rgba(255, 255, 255, 0.75)",
  },
  content: {
    width: "100%",
    padding: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
};

const PopupModal = (props) => {
  const { children, modalOpen, setModalOpen, isOneClick } = props;

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isOneClick) {
      setModalOpen(true);
    }
    return () => {
      isMounted = false;
    };
  }, [isOneClick]);

  return (
    <Modal
      isOpen={modalOpen}
      ariaHideApp={false}
      onRequestClose={() => setModalOpen(false)}
      contentLabel="Action Modal"
      closeTimeoutMS={200}
      style={customStyles}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
    >
      {children}
    </Modal>
  );
};

export default PopupModal;
