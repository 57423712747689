import { useSelector, useDispatch } from "react-redux";
import {
  countryChanged,
  getCountry,
  getCountriesList,
} from "../../../reducers/ActionFormReducer";
import Select from "../../Internal/Select";

const CountryList = ({ id, label, hide, errorMsg }) => {
  const country = useSelector(getCountry);
  const countries = useSelector(getCountriesList);
  const dispatch = useDispatch();

  const onCountryChange = (country) => {
    dispatch(countryChanged(country));
  };

  return (
    <Select
      id={id}
      key="country"
      options={countries}
      name="country"
      value={country}
      onChange={onCountryChange}
      placeholder={label}
      required={true}
      hideField={hide}
      errorMsg={errorMsg}
    />
  );
};

export default CountryList;
