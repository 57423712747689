const InstructionField = ({ dataTestId, placeholder }) => {
  return (
    <div
      data-testid={dataTestId}
      className="bg-gray-100 text-gray-400 p-3 font-semibold"
    >
      {placeholder}
    </div>
  );
};

export default InstructionField;
