import paypal from "braintree-web/paypal";
import { useEffect, useCallback, useState } from "react";

const Paypal = (props) => {
  const { client, setPaypalInstance } = props;

  const [initializing, setInit] = useState(true);

  const onPayPalCreate = useCallback(
    (error, instance) => {
      if (error) return;
      setInit(false);
      setPaypalInstance(instance);
    },
    [setPaypalInstance]
  );

  useEffect(() => {
    if (client && initializing) {
      paypal.create({ client }, onPayPalCreate);
    }
  }, [props, client, initializing, onPayPalCreate]);

  return null;
};

export default Paypal;
