import { useTranslations } from "next-intl";
import CurrencyAmount from "../../Donation/Helpers/CurrrencyAmount";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  getCurrentStep,
  changeStep,
  getDonationAmount,
  getCurrency,
} from "../../../reducers/DonationReducer";

const Step = (props) => {
  const t = useTranslations("default");
  const { index, step, loading } = props;
  const { name } = step;
  const dispatch = useDispatch();
  const currentStep = useSelector(getCurrentStep);
  const amount = useSelector(getDonationAmount);
  const currency = useSelector(getCurrency);
  const stepWrapperClasses =
    "w-12 h-12 flex items-center justify-center rounded-full text-xl lg:font-semibold font-medium z-10";

  function SkeletonStepWrapper({ children }) {
    return (
      <div data-testid="steps-circles-skeleton" className={stepWrapperClasses}>
        {children}
      </div>
    );
  }

  return (
    <div className="Step-wrapper flex flex-col">
      {loading ? (
        <Skeleton circle height="100%" wrapper={SkeletonStepWrapper} />
      ) : (
        <>
          <div
            data-testid={`step-${index + 1}`}
            className={`Step ${stepWrapperClasses} ${
              currentStep === index
                ? "bg-purple-primary text-white Step-active"
                : "bg-gray-300"
            } ${
              currentStep > index
                ? "bg-gray-400 hover:bg-purple-primary cursor-pointer Step-complete"
                : ""
            }`}
            onClick={() => {
              if (currentStep > index) {
                dispatch(changeStep(index));
              }
            }}
            key={index + Math.random()}
          >
            {t(`${index + 1}`)}
          </div>
        </>
      )}
      <span
        className="Step-label pt-2 lg:font-semibold font-medium text-sm text-center"
        data-testid={`step-${index + 1}-label`}
      >
        {loading ? (
          <Skeleton containerTestId="steps-labels-skeleton" />
        ) : (
          <>
            {index === 0 && amount ? (
              <CurrencyAmount amount={amount} currency={currency} />
            ) : (
              name
            )}
          </>
        )}
      </span>
    </div>
  );
};

export default Step;
