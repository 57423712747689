import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslations } from "next-intl";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import Checkbox from "../Internal/Checkbox";
import Input from "../Internal/Input";
import { InputField } from "./Fields/InputField";
import CountryList from "./Fields/CountryList";
import Select from "../Internal/Select";
import InstructionField from "../Internal/InstructionField";
import TextArea from "../Internal/TextArea";
import Choice from "../Internal/Choice";
import Consent from "../Internal/Consent";

import { getMember, getIsMemberPresent } from "../../reducers/MemberReducer";
import { setField, getCountry } from "../../reducers/ActionFormReducer";
import { getConsent, getShowConsentAsk } from "../../reducers/ConsentReducer";
import { removeSavedMemberDetails } from "../../lib/removeSavedMemberDetails";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const Form = ({ fields, errors, loading }) => {
  const member = useSelector(getMember);

  const isMemberPresent = useSelector(getIsMemberPresent);
  const showConsentAsk = useSelector(getShowConsentAsk);
  const consented = useSelector(getConsent);
  const country = useSelector(getCountry);
  const dispatch = useDispatch();
  const disableDoubleOptIn = useFeatureIsOn("double_opt_in") === false;

  function displayConsentBox() {
    if (isMemberPresent && showConsentAsk) {
      return consented === null;
    } else if (disableDoubleOptIn && ["DE", "AT"].includes(country?.value)) {
      return true;
    } else {
      return showConsentAsk;
    }
  }

  const t = useTranslations("default");

  const handleScroll = () => {
    const scrollablePetitionEl = document.getElementById("petition-form");
    const ctaButtonWrapperEl = document.getElementById("cta-wrapper");
    if (scrollablePetitionEl.scrollTop > 10) {
      scrollablePetitionEl.style.paddingBottom = "0";
      ctaButtonWrapperEl.style.position = "relative";
      ctaButtonWrapperEl.style.backgroundColor = "transparent";
    } else {
      scrollablePetitionEl.style.paddingBottom = "120px";
      ctaButtonWrapperEl.style.position = "absolute";
      ctaButtonWrapperEl.style.backgroundColor = "#efefef";
    }
  };

  const getErrorMessage = (fieldName) => {
    if (!errors || !fieldName) return;
    const fieldErrors = errors.map((e) => {
      return e[fieldName] || "";
    });

    return fieldErrors[0];
  };

  useEffect(() => {
    if (
      document.getElementById("petition-form").scrollHeight !=
      document.getElementById("petition-form").clientHeight
    ) {
      document
        .getElementById("petition-form")
        ?.addEventListener("scroll", handleScroll);
    }

    return () =>
      document
        .getElementById("petition-form")
        ?.removeEventListener("scroll", handleScroll);
  }, []);

  const onInputFieldChange = (e) => dispatch(setField(e));

  const formElements = fields.map(
    ({
      id,
      data_type,
      name,
      label,
      value,
      required,
      choices,
      defaultValue,
    }) => {
      switch (data_type) {
        case "text":
          return (
            <>
              {loading ? (
                <Skeleton
                  containerTestId={`${data_type}-input-skeleton`}
                  count={1}
                  height={54}
                />
              ) : (
                <InputField
                  id={id}
                  key={name}
                  name={name}
                  placeholder={label}
                  required={required}
                  type={data_type}
                  value={fields[name]}
                  onChange={onInputFieldChange}
                  hide={isMemberPresent && member[name]}
                  errorMsg={getErrorMessage(name)}
                />
              )}
            </>
          );
        case "email":
          return (
            <>
              {loading ? (
                <Skeleton
                  containerTestId={`${data_type}-input-skeleton`}
                  count={1}
                  height={54}
                />
              ) : (
                <InputField
                  id={id}
                  key={name}
                  name={name}
                  placeholder={label}
                  required={required}
                  type={data_type}
                  value={fields[name]}
                  onChange={onInputFieldChange}
                  hide={isMemberPresent && member[name]}
                  errorMsg={getErrorMessage(name)}
                  pattern={
                    "^[a-zA-Z0-9._%+\\-]+@[a-zA-Z0-9.\\-]+\\.[a-zA-Z]{2,}$"
                  }
                  title={t("enter_valid_email")}
                />
              )}
            </>
          );
        case "postal":
          return (
            <>
              {loading ? (
                <Skeleton
                  containerTestId="postal-input-skeleton"
                  count={1}
                  height={54}
                />
              ) : (
                <Input
                  id={id}
                  key={name}
                  name={name}
                  value={value}
                  hideField={isMemberPresent && member[name]}
                  placeholder={label}
                  required={required}
                  type={"text"}
                  maxLength={249}
                  onChange={onInputFieldChange}
                  errorMsg={getErrorMessage(name)}
                />
              )}
            </>
          );
        case "phone":
          return (
            <>
              {loading ? (
                <Skeleton
                  containerTestId="phone-input-skeleton"
                  count={1}
                  height={54}
                />
              ) : (
                <InputField
                  id={id}
                  key={name}
                  name={name}
                  placeholder={label}
                  required={required}
                  value={fields[name]}
                  type={"tel"}
                  onChange={onInputFieldChange}
                  hide={isMemberPresent && member[name]}
                  errorMsg={getErrorMessage(name)}
                />
              )}
            </>
          );
        case "checkbox":
          return (
            <>
              {loading ? (
                <Skeleton count={1} height={54} />
              ) : (
                <Checkbox
                  id={id}
                  name={name}
                  placeholder={label}
                  checked={defaultValue}
                  onChange={onInputFieldChange}
                  required={required}
                  errorMsg={getErrorMessage(name)}
                />
              )}
            </>
          );
        case "country": {
          return (
            <>
              {loading ? (
                <Skeleton
                  containerTestId="country-list-skeleton"
                  count={1}
                  height={54}
                />
              ) : (
                <CountryList
                  id={id}
                  label={label}
                  hide={isMemberPresent && member.country}
                />
              )}
            </>
          );
        }
        case "dropdown":
          return (
            <Select
              id={id}
              key={name}
              options={choices}
              name={name}
              placeholder={label}
              required={required}
              errorMsg={getErrorMessage(name)}
            />
          );
        case "instruction":
          return <InstructionField dataTestId={id} placeholder={label} />;
        case "choice":
          return (
            <Choice
              id={id}
              placeholder={label}
              name={name}
              options={choices}
              errorMsg={getErrorMessage(name)}
            />
          );
        case "paragraph":
          return (
            <TextArea
              name={name}
              placeholder={label}
              id={id}
              value={value}
              required={true}
              onChange={onInputFieldChange}
              errorMsg={getErrorMessage(name)}
            />
          );
        default:
          console.log("no match", data_type);
      }
    }
  );

  return (
    <div>
      {isMemberPresent && !loading && (
        <div
          data-testid="petition-data-auto-fill"
          className="flex flex-row justify-between mt-3 mb-6 h-6"
        >
          <div className="flex flex-row">
            <img
              src="/assets/checked.svg"
              alt="checked"
              width={20}
              height={20}
            />
            <span
              data-testid="auto-fill-member-name"
              className="ps-2 priFont-semiBold"
            >
              {member.name}
            </span>
          </div>
          <div
            className="text-purple-primary text-sm cursor-pointer"
            id="clear-member-data"
            onClick={() => {
              removeSavedMemberDetails(dispatch);
            }}
          >
            {t("sign_out")}
          </div>
        </div>
      )}
      {formElements}
      {!loading && displayConsentBox() && <Consent />}
    </div>
  );
};

export default Form;
