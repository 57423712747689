const MessageDescriptor = ({ paymentMethod }) => {
  const type = paymentMethod.instrument_type;
  switch (type) {
    case "credit_card":
      return `${paymentMethod.card_type} ending in ${paymentMethod.last_4}`;
    case "paypal_account":
      return `Paypal ${paymentMethod.email}`;
    default:
      return "Payment method";
  }
};

const PaymentMethodItem = ({ paymentMethod, checked, onChange }) => {
  return (
    <div
      id={`PaymentMethod-${paymentMethod.id}`}
      className="PaymentMethod my-3"
    >
      <label>
        {onChange && (
          <input
            type="radio"
            className="border-none bg-gray-300 focus:bg-purple-primary focus:ring-transparent text-purple-primary"
            checked={checked}
            onChange={(e) => {
              if (e.target.checked && typeof onChange === "function") {
                onChange(paymentMethod);
              }
            }}
          />
        )}
        <span
          className={`${
            onChange ? "ps-3" : "ps-0"
          } text-md tracking-wider priFont-regular font-bold payment-method-detail`}
        >
          <MessageDescriptor paymentMethod={paymentMethod} />
        </span>
      </label>
    </div>
  );
};

export default PaymentMethodItem;
