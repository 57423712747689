export const formatPaymentErrors = (errorResponse) => {
  const errors = [];
  if (!errorResponse) return errors;

  if (typeof errorResponse === "string") {
    errors.push(errorResponse);
  }

  if (errorResponse.error) {
    errors.push(errorResponse.error.code);
  }

  if (errorResponse.code) {
    errors.push(errorResponse.code);
  }

  errorResponse.errors?.forEach((e) => {
    if (e.message) {
      errors.push(e.message);
    } else if (e.code) {
      errors.push(e.code);
    }
  });

  return errors;
};

export const getErrorsByCode = (code) => {
  let errors = [];
  switch (code) {
    case "2000":
    case "2044":
    case "2046":
      errors = ["braintree_bankRejected"];
      break;
    case "2005":
    case "2006":
    case "2010":
      errors = ["braintree_transactionDeclined"];
      break;
    case "2001":
      errors = ["braintree_insufficientFunds", "braintree_tryAgain"];
      break;
    case "2004":
      errors = ["braintree_expiredCard", "braintree_tryAgain"];
      break;
    case "2074":
      errors = ["braintree_paypalDeclined"];
      break;
    case "91567":
      errors = ["braintree_unsupportedPayment"];
      break;
    default:
      errors = ["donation_errorMsgNotified"];
      break;
  }

  return errors;
};
