import { useDispatch, useSelector } from "react-redux";
import { useTranslations } from "next-intl";
import {
  getStoreInVault,
  setStoreInVault,
} from "../../../reducers/DonationReducer";
import Checkbox from "../../Internal/Checkbox";

const SecureStorePaymentOption = (props) => {
  const t = useTranslations("default");
  const dispatch = useDispatch();
  const storeInVault = useSelector(getStoreInVault);

  const handleGaTracking = (checked) => {
    const storeInVaultChoice = checked ? "checked" : "unchecked";
  };

  return (
    <Checkbox
      name="Checkbox_Payment__configCheckbox_Payment__config"
      id="Checkbox_Payment__config"
      dataTestId="storeInVault"
      classes="Checkbox Payment__config"
      placeholder={t("donation_storeInVault")}
      checked={storeInVault}
      onChange={(e) => {
        handleGaTracking(e.currentTarget.checked);
        dispatch(setStoreInVault(e.currentTarget.checked));
      }}
    />
  );
};

export default SecureStorePaymentOption;
