import { useDispatch } from "react-redux";
import CurrencyAmount from "../Helpers/CurrrencyAmount";
import {
  changeStep,
  selectDonationAmount,
  setCovertransactionCost,
  setIsCustomAmount,
  setPaymentMethods,
  setSelectedAmountButton,
} from "../../../reducers/DonationReducer";
import SkeletonWrapper from "../../common/SkeletonWrapper";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { GA4_SELECT_ITEM } from "../../TrackingScripts/helpers";
import analytics from "../../../lib/analytics";
import { batchActions } from "../../../lib/redux-utils";

const AmountButton = (props) => {
  const { amount, currency, loading, dataTestId, name } = props;

  const buttonLayoutClasses =
    "flex justify-center items-center rounded-md my-2 mx-0.5 h-16 w-46";

  const dispatch = useDispatch();
  const ga4Tracking = ({ amount, name }) => {
    analytics.track(GA4_SELECT_ITEM, {
      item_list_name: "donation bands",
      items: [
        {
          item_id: name,
          price: amount,
        },
      ],
    });
  };
  const handleClick = (e) => {
    try {
      e.preventDefault();
      const actions = [];
      actions.push(setSelectedAmountButton(e.target.name));
      actions.push(setIsCustomAmount(false));
      actions.push(selectDonationAmount(amount));
      actions.push(changeStep(1));
      actions.push(setPaymentMethods());
      actions.push(setCovertransactionCost(false));

      batchActions(dispatch, actions);
      ga4Tracking({ amount, name });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {loading ? (
        <>
          <SkeletonWrapper
            className={buttonLayoutClasses}
            dataTestId="amount-buttons-skeleton"
          >
            <Skeleton width={153} height={64} />
          </SkeletonWrapper>
        </>
      ) : (
        <button
          data-testid={dataTestId}
          name={name}
          id={name}
          className={`DonationBand-Button ${buttonLayoutClasses} bg-purple-primary hover:bg-purple-hover focus:outline-none`}
          onClick={handleClick}
        >
          <span className="text-2xl text-white pointer-events-none">
            <span className="font-extrabold priFont-extraBold pointer-events-none">
              <CurrencyAmount currency={currency} amount={amount} />
            </span>
          </span>
        </button>
      )}
    </>
  );
};

export default AmountButton;
