const TextArea = ({
  name,
  placeholder,
  id,
  value,
  errorMsg,
  onChange,
  required,
  hidden,
}) => {
  return (
    <>
      {!hidden && (
        <div className="mb-2">
          <textarea
            className={`border w-full bg-gray-100 resize-none focus:border-purple-primary focus:ring-transparent ${
              errorMsg ? "border-red-600" : "border-transparent"
            }`}
            name={name}
            placeholder={placeholder}
            id={id}
            rows={4}
            value={value}
            maxLength="9999"
            spellCheck="false"
            required={required}
            onChange={onChange}
          />
          {errorMsg && <div className="text-red-600 text-sm">{errorMsg}</div>}
        </div>
      )}
    </>
  );
};

export default TextArea;
