import { useDispatch, useSelector } from "react-redux";

import PageContent from "../components/common/PageContent";
import MobileLayout from "../components/Petition/MobileLayout";
import PetitionBar from "../components/Petition/PetitionBar";
import Scroll from "../components/Petition/Scroll";
import "react-loading-skeleton/dist/skeleton.css";
import { setPageDetails } from "../reducers/ActionFormReducer";
import { selectIsMobile } from "../reducers/PageReducer";
import CampaignersPopup from "../components/CampaignersPopup/CampaignersPopup";

const Petition = (props) => {
  const {
    countriesList,
    campaign_action_count,
    id,
    language,
    image,
    title,
    slug,
    content,
    sources,
    share_buttons,
    petition,
    fundraiser,
    loading,
    thermometer: { actions: { offset = 0, active = true } } = {},
    publish_status,
    scrollToDonate,
    postActionAsk,
  } = props;

  const dispatch = useDispatch();
  dispatch(setPageDetails({ id, slug, title, petition, fundraiser }));
  const isMobile = useSelector(selectIsMobile);

  const { target, cta, description, form } = petition;

  const petitionBarProps = {
    cta,
    countriesList,
    form,
    description,
    target,
    campaign_action_count,
    id,
    language,
    offset,
    active,
    isMobile,
  };

  return (
    <>
      {/* Main content */}
      <div className="min-h-screen">
        <div className="max-w-screen-desktop mx-auto">
          <div className="flex lg:flex-row flex-col lg:mb-8">
            <div className="flex flex-col lg:pe-4 w-full xl:w-8/12 lg:w-7/12">
              <PageContent
                imagePath={image?.original.path}
                title={title}
                content={content}
                sources={sources}
                language={language}
                achieved={campaign_action_count + offset}
                active={active}
              ></PageContent>
              {isMobile && (
                <>
                  <MobileLayout
                    cta={cta}
                    loading={loading}
                    petitionBarProps={petitionBarProps}
                  ></MobileLayout>
                </>
              )}
            </div>
            <div className="lg:w-5/12 xl:w-4/12 w-full">
              {!isMobile && (
                <PetitionBar {...petitionBarProps} loading={loading} />
              )}
            </div>
          </div>
        </div>
        <Scroll
          postActionAsk={postActionAsk}
          scrollToDonate={scrollToDonate}
          shareButtons={share_buttons}
          language={language}
          title={title}
        ></Scroll>
      </div>
      {!isMobile && (
        <CampaignersPopup publish_status={publish_status} lang={language} />
      )}
    </>
  );
};

export default Petition;
