import classnames from "classnames";

function Button(props) {
  const {
    type,
    onClick,
    classes,
    disabled,
    dataTestId,
    backgroundColor,
    textColor,
    border,
  } = props;
  const bgColor = backgroundColor ? backgroundColor : "bg-purple-primary";
  const txtColor = textColor ? textColor : "text-white";
  const borderStyle = border ? border : "border";
  const baseClasses = `mx-4 p-4 w-full ${bgColor} uppercase ${txtColor} outline-0 flex items-center justify-center priFont-extraBold ${borderStyle} rounded-md focus:outline-none`;
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classnames(baseClasses, classes)}
      data-testid={dataTestId}
    >
      {props.children}
    </button>
  );
}

export default Button;
