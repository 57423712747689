import { useTranslations } from "next-intl";
import { NextSeo } from "next-seo";
import Head from "next/head";
import { buildURL } from "react-imgix";

const PageHead = ({ title, slug, image }) => {
  const t = useTranslations("default");
  const SITE_DOMAIN = process.env.NEXT_PUBLIC_SITE_DOMAIN;
  const description = t("branding_description");
  const handle = t("share_twitter_handle");

  const imageSrc = () => {
    if (!image) return null;

    return buildURL(
      `${process.env.NEXT_PUBLIC_IMG_HOST}${image.original.path}`,
      {
        crop: "edges",
        fm: "webp",
        auto: "format",
        fit: "crop",
        w: 1200,
        h: 630,
      }
    );
  };

  const url = `${SITE_DOMAIN}/a/${slug}`;
  const siteName = "Ekō";
  const images = image
    ? [
        {
          url: imageSrc(),
          width: 1200,
          height: 630,
          alt: title,
        },
      ]
    : [];

  return (
    <>
      <Head>
        <script
          defer
          src={`${process.env.NEXT_PUBLIC_CHAMPAIGN_API_HOST}/api/members/set_payment_methods`}
        />
      </Head>
      <NextSeo
        additionalMetaTags={[
          {
            name: "viewport",
            content: "initial-scale=1.0, width=device-width",
          },
        ]}
        additionalLinkTags={[
          {
            rel: "icon",
            href: "https://champaign-assets-production.s3.us-west-2.amazonaws.com/unique/favico.svg",
            type: "image/x-icon",
          },
          {
            rel: "apple-touch-icon",
            href: "https://champaign-assets-production.s3.us-west-2.amazonaws.com/unique/favicon-avatar.png",
            type: "image/png",
            sizes: "32x32",
          },
          {
            rel: "mask-icon",
            href: "https://champaign-assets-production.s3.us-west-2.amazonaws.com/unique/favico.svg",
            type: "image/png",
            sizes: "32x32",
          },
          {
            rel: "preconnect",
            href: "https://cdn.growthbook.io",
            crossOrigin: "true",
          },
          {
            rel: "preconnect",
            href: "https://www.googletagmanager.com",
            crossOrigin: "true",
          },
          {
            rel: "preconnect",
            href: "https://www.google.com",
            crossOrigin: "true",
          },
          {
            rel: "preconnect",
            href: "https://sumofus.imgix.net",
            crossOrigin: "true",
          },
          {
            rel: "preconnect",
            href: "https://o142675.ingest.sentry.io",
            crossOrigin: "true",
          },
        ]}
        title={title}
        description={description}
        openGraph={{
          url: `${url}`,
          title,
          description,
          images,
          site_name: siteName,
        }}
        twitter={{
          site: handle,
          cardType: "summary_large_image",
          domain: SITE_DOMAIN,
        }}
      />
    </>
  );
};

export default PageHead;
