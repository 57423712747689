import { useState, useEffect } from "react";
import axios from "axios";

// custom hook for performing GET requests with dependencies
const useFetch = (url, initialValue, options) => {
  let blocked = ((options && options.depends) || []).reduce(function (
    acc,
    dep
  ) {
    return acc || !dep;
  },
  false);

  const [data, setData] = useState(initialValue);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async function () {
      try {
        setLoading(true);
        const response = await axios.get(url);
        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (!blocked) fetchData();
    else {
      setLoading(false);
      setData(null);
    }
  }, [url, blocked]);
  return { loading, data };
};

export default useFetch;
