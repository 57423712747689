import { getFirstName, getLastName } from "./utils/nameSplitter";

export const ProcessLocalPayment = async ({
  localPaymentInstance,
  pageId,
  data,
  paymentType,
}) => {
  const { user, amount, currency } = data;
  const { name, country } = user;

  const opts = {
    paymentType,
    amount: amount.toFixed(2),
    fallback: {
      // see Fallback section for details on these params
      url: `${window.location.href}/follow-up`,
      buttonText: "Complete Payment",
    },
    currencyCode: currency,
    countryCode: country,
    paymentTypeCountryCode: country,
    givenName: getFirstName(name),
    surname: getLastName(name),
    address: {
      countryCode: country,
    },
    onPaymentStart: function (localData, start) {
      const { paymentId } = localData;

      const payload = {
        paymentId,
        data,
      };

      fetch(`/api/transaction/local_payment/?id=${pageId}`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: { "Content-Type": "application/json" },
      }).then((resp) => start());
    },
  };

  try {
    const resp = (await localPaymentInstance?.startPayment(opts)) || {};
    return resp;
  } catch (e) {
    console.log("LocalPayment ERROR", e);
  }
};
