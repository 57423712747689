import React, { memo, useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import CookieConsent from "../../common/Banners/CookieConsent";
import PopupModal from "../../Internal/PopupModal";
import PetitionBar from "../PetitionBar";
import MobileCTA from "../../Internal/MobileCTA";
import {
  closeCookieConsentBanner,
  selectShowCookieConsentBanner,
} from "../../../reducers/ConsentReducer";
import { getCtaCompleted } from "../../../reducers/ActionFormReducer";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const MobileLayout = ({ cta, loading, petitionBarProps }) => {
  const t = useTranslations("default");
  const { query, isReady } = useRouter();

  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const showCookieConsentBanner = useSelector(selectShowCookieConsentBanner);
  const ctaCompleted = useSelector(getCtaCompleted);
  const mobileCTARef = useRef();
  const isCookieFeatureOn = useFeatureIsOn("cookie-preferences");

  useEffect(() => {
    let isMounted = true;
    if (ctaCompleted && isMounted && mobileCTARef?.current !== null) {
      mobileCTARef.current.classList.add("hidden");
    }

    return () => {
      isMounted = false;
    };
  }, [ctaCompleted]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && showCookieConsentBanner && !isCookieFeatureOn) {
      setShowCookieBanner(true);
    }

    return () => {
      isMounted = false;
    };
  }, [showCookieConsentBanner, isCookieFeatureOn]);

  return (
    <>
      <MobileCTA
        cta={cta || t("sign_it")}
        setModalOpen={setModalOpen}
        loading={loading}
        ref={mobileCTARef}
      />

      {showCookieBanner && showCookieConsentBanner && (
        <CookieConsent
          closeCookieConsentBanner={() => dispatch(closeCookieConsentBanner())}
        />
      )}
      <PopupModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        id="mobile-cta-bar"
        className={ctaCompleted ? "hidden" : ""}
      >
        <PetitionBar
          {...petitionBarProps}
          isMobile={true}
          openModal={setModalOpen}
          loading={loading}
        />
      </PopupModal>
    </>
  );
};

MobileLayout.propTypes = {
  cta: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  petitionBarProps: PropTypes.object.isRequired,
};

MobileLayout.defaultProps = {
  loading: true,
  petitionBarProps: {},
};

export default memo(MobileLayout);
