import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslations } from "next-intl";

import Button from "../../Internal/Button";
import AmountButton from "./AmountButton";
import CurrencySelector from "./CurrenySelector";
import CustomAmount from "./CustomAmount";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  changeStep,
  getCurrency,
  getDonationAmount,
  selectCapDonationBands,
  setPaymentMethods,
} from "../../../reducers/DonationReducer";

const DonationBand = (props) => {
  const { loading, dataTestId } = props;
  const dispatch = useDispatch();
  const currency = useSelector(getCurrency);
  const amount = useSelector(getDonationAmount);
  const donationBands = useSelector(selectCapDonationBands);
  const t = useTranslations("default");

  const [showCurrencySelector, setShowSelector] = useState(false);

  return (
    <div
      className="AmountSelection-Container w-full flex flex-col justify-center items-center"
      data-testid={dataTestId}
    >
      <div className="DonationBands-Container py-8 flex flex-wrap w-full justify-between">
        {donationBands[currency].map((amount, index) => {
          return (
            <AmountButton
              amount={amount}
              currency={currency}
              key={amount * index}
              loading={loading}
              dataTestId={`amountButton-${index}`}
              name={`DonationBands-${index + 1}`}
            />
          );
        })}
        <CustomAmount loading={loading} />
      </div>

      <div className="AmountSelection-Currency w-full text-center">
        <div className="text-sm mb-6">
          {loading ? (
            <Skeleton />
          ) : (
            <>
              <span className="AmountSelection-Currency-hint">
                {t("values_shown", { currency })}
              </span>
              <span
                data-testid="switchCurrency"
                id="switchCurrency"
                className="AmountSelection-Currency-toggle text-purple-primary cursor-pointer"
                onClick={() => setShowSelector(true)}
              >
                {t("switchCurrency")}
              </span>
            </>
          )}
        </div>

        {showCurrencySelector && (
          <CurrencySelector
            id="AmountSelection-Currency-selector"
            donationBands={donationBands}
          />
        )}

        {amount && (
          <Button
            classes="AmountSelection__proceed-button"
            onClick={() => {
              dispatch(changeStep(1));
              dispatch(setPaymentMethods());
            }}
            disabled={amount === null}
            dataTestId="proceed-to-payment"
          >
            <span className="text-sm lg:text-lg priFont-extraBold">
              {t("button_proceedPayment")}
            </span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default DonationBand;
