import React, { memo, useEffect, useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../Internal/Button";
import { getCtaCompleted } from "../../../reducers/ActionFormReducer";
import {
  setCurrentView,
  selectCurrentView,
} from "../../../reducers/PageReducer";

const Prompt = forwardRef(
  (
    {
      title,
      acceptMessage,
      declineMessage,
      ctaMessage,
      askMessage,
      handleAccept,
      handleDecline,
      hasCustomMessage,
      id,
      children,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const signed = useSelector(getCtaCompleted);
    const currentView = useSelector(selectCurrentView);

    useEffect(() => {
      let isMounted = true;
      if (isMounted && signed && currentView === "default" && ref?.current) {
        ref.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
        dispatch(setCurrentView("postActionAsk"));
      }

      return () => {
        isMounted = false;
      };
    }, [ref, signed, currentView, dispatch]);

    return (
      <>
        <div
          className={`${
            signed ? "" : "hidden"
          } min-h-screen lg:mx-24 mx-6 flex items-center justify-start md:pt-60 pt-52 flex-col lg:pt-40 lg:mt-0 md:mt-0 mt-48`}
          id="prompt"
          ref={ref}
        >
          {children}
          <div className="text-black font-semibold priFont-medium text-3xl text-center lg:mx-20 md:mx-5 mx-6">
            {title}
          </div>
          <div className="lg:text-3xl text-base text-md mt-4 lg:mt-8 md:mt-8 lg:mb-12 lg:mx-24 md:mx-8 mx-5 p-3.5 text-center md:font-normal font-normal">
            <p
              datatest-id="prompt-message"
              className="leading-normal md:leading-loose priFont-regular"
              dangerouslySetInnerHTML={{ __html: askMessage }}
            ></p>
            {!hasCustomMessage && (
              <p className="priFont-regular">{ctaMessage}</p>
            )}
          </div>
          <div className="flex md:flex-row flex-col justify-center items-center w-full lg:w-3/5">
            <Button
              type="button"
              onClick={async (e) =>
                await handleAccept({
                  target: e.target,
                  id: `${id}-two-step-accept`,
                })
              }
              classes="prompt-yes py-4 px-6 md:my-0 lg:my-4 mb-8 lg:mt-4 mt-1 mx-4 rounded-md h-18 max-w-285 hover:shadow-m2xl"
              dataTestId="prompt-yes"
              id={`${id}-two-step-accept`}
            >
              <span className="text-2xl font-normal capitalize priFont-medium px-3">
                {acceptMessage}
              </span>
              <img
                src="https://champaign-assets-production.s3.us-west-2.amazonaws.com/unique/favourite-filled.png"
                alt=""
                aria-hidden="true"
              />
            </Button>
            <Button
              type="button"
              onClick={(e) =>
                handleDecline({
                  target: e.target,
                  id: `${id}-two-step-decline`,
                })
              }
              backgroundColor="bg-white"
              textColor="text-black"
              classes="py-4 px-6 rounded-md priFont-semiBold lg:h-18 md:h-18 lg:max-w-285 md:max-w-285 max-w-245 h-12 hover:shadow-m2xl"
              border="border-solid border-purple-primary border-2"
              dataTestId="prompt-no"
            >
              <span className="lg:text-2xl md:text-2xl text-xl font-normal normal-case priFont-medium">
                {declineMessage}
              </span>
            </Button>
          </div>
        </div>
      </>
    );
  }
);

Prompt.propTypes = {
  title: PropTypes.string,
  handleAccept: PropTypes.func.isRequired,
  handleDecline: PropTypes.func.isRequired,
  acceptMessage: PropTypes.string.isRequired,
  declineMessage: PropTypes.string.isRequired,
  ctaMessage: PropTypes.string.isRequired,
  askMessage: PropTypes.string,
  hasCustomMessage: PropTypes.bool,
  id: PropTypes.string,
  children: PropTypes.node,
};

Prompt.defaultProps = {
  handleAccept: () => {},
  handleDecline: () => {},
  acceptMessage: "",
  declineMessage: "",
  ctaMessage: "",
};

Prompt.displayName = "Prompt";

export default memo(Prompt);
