import { useTranslations } from "next-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentPaymentType,
  getPaymentTypes,
  setCurrentPaymentType,
} from "../../../reducers/DonationReducer";

const PaymentTypeSelection = (props) => {
  const { loading } = props;
  const dispatch = useDispatch();
  const paymentOptions = useSelector(getPaymentTypes);
  const currentPaymentType = useSelector(getCurrentPaymentType);
  const t = useTranslations("default");

  function optionName(key) {
    if (key === "card") return t("credit_debit_card");
    return t(key);
  }

  return (
    <div>
      <div className="text-sm font-extrabold">{t("payment_method")}</div>
      <div
        className="flex ms-1 flex-col my-4"
        data-testid="paymentTypeSelection"
      >
        {paymentOptions.map((key) => {
          return (
            <div
              className="flex flex-row items-center py-2"
              key={Math.random()}
            >
              <input
                type="radio"
                id={key}
                disabled={loading}
                value={key}
                checked={currentPaymentType === key}
                data-testid={optionName(key)}
                className="border-none bg-gray-300 focus:bg-purple-primary focus:ring-transparent text-purple-primary"
                onChange={(e) => {
                  dispatch(setCurrentPaymentType(e.currentTarget.value));
                }}
              />
              <label htmlFor={key}>
                <span className="ps-2 cursor-pointer">{optionName(key)}</span>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PaymentTypeSelection;
