import Skeleton from "react-loading-skeleton";
import SkeletonWrapper from "../../common/SkeletonWrapper";
import "react-loading-skeleton/dist/skeleton.css";
import { forwardRef } from "react";

// eslint-disable-next-line react/display-name
const MobileCTA = forwardRef(({ setModalOpen, cta, loading }, ref) => {
  const layoutClasses =
    "px-3 w-full outline-0 flex items-center justify-center";
  return (
    <div
      className="p-4 w-full bg-gray-200 sticky bottom-0 lg:hidden block"
      id="mobile-cta-bar"
      ref={ref}
    >
      {loading ? (
        <SkeletonWrapper className={layoutClasses}>
          <Skeleton height={54} width={380} />
        </SkeletonWrapper>
      ) : (
        <button
          data-testid="mobile-cta"
          type="button"
          onClick={() => setModalOpen(true)}
          className={`${layoutClasses} h-16 bg-purple-primary uppercase text-white text-md priFont-extraBold border rounded-md focus:outline-none`}
        >
          {cta}
        </button>
      )}
    </div>
  );
});

export default MobileCTA;
