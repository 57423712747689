import { useTranslations } from "next-intl";
import { useSelector } from "react-redux";
import { getFormValues } from "../../../reducers/DonationReducer";
import {
  getIsMemberPresent,
  getMemberName,
} from "../../../reducers/MemberReducer";

const MemberConsentMsg = (props) => {
  //  Hide the payment message component as part of montly sustainers project suggested by FR team.
  return null;
  // Refer prosecco PR - #423

  const { duration } = props;
  const t = useTranslations("default");

  const isMemberPresent = useSelector(getIsMemberPresent);
  const savedName = useSelector(getMemberName);
  const formName = useSelector(getFormValues).name;
  let memberName = null;
  if (isMemberPresent) memberName = savedName;
  else memberName = formName;

  return (
    <div className="flex">
      <div className="font-medium">
        <span>{memberName ? `${memberName}: ` : null}</span>
        <span>{t("member_consent_msg", { duration })}</span>
      </div>
    </div>
  );
};

export default MemberConsentMsg;
