import { useSelector } from "react-redux";
import { selectMintHeader } from "../../../reducers/PageReducer";
const MobileLogo = () => {
  const showLogo = !useSelector(selectMintHeader);
  return (
    showLogo && (
      <img
        src="https://champaign-assets-production.s3.us-west-2.amazonaws.com/unique/Eko_Logo_Purple.svg"
        alt="org-logo"
        width={100}
        height={55}
        className="absolute top-1 left-2 lg:hidden block"
      />
    )
  );
};

export default MobileLogo;
