import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslations } from "next-intl";

import HeroImage from "../components/Internal/HeroImage";
import MobileLogo from "../components/Internal/MobileLogo";
import CampaignTitle from "../components/Internal/CampaignTitle";
import BodyContent from "../components/Internal/BodyContent";
import MobileCTA from "../components/Internal/MobileCTA";
import SourceList from "../components/Internal/SourceList";
import PopupModal from "../components/Internal/PopupModal";
import Donation from "../components/Donation";
import CookieConsent from "../components/common/Banners/CookieConsent";
import "react-loading-skeleton/dist/skeleton.css";

import { selectIsMobile, selectOneClick } from "../reducers/PageReducer";
import { setForm, setDonationFormId } from "../reducers/DonationReducer";
import { setPageId, setPageSlug, setPageTitle } from "../reducers/PageReducer";
import {
  closeCookieConsentBanner,
  selectShowCookieConsentBanner,
} from "../reducers/ConsentReducer";
import { batchActions } from "../lib/redux-utils";
import CampaignersPopup from "../components/CampaignersPopup/CampaignersPopup";
import { useFeatureIsOn } from "@growthbook/growthbook-react";

const Fundraiser = (props) => {
  const {
    cta,
    id,
    language,
    image,
    title,
    slug,
    content,
    sources,
    fundraiser,
    loading = true,
    publish_status,
  } = props;

  const t = useTranslations("default");

  const dispatch = useDispatch();

  // Initialize page props - id, slug, title
  const actions = [
    setPageId(id),
    setPageSlug(slug),
    setPageTitle(title),
    setDonationFormId(fundraiser.form_id),
    setForm(fundraiser.form),
  ];
  batchActions(dispatch, actions);

  const [modalOpen, setModalOpen] = useState(false);
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const showCookieConsentBanner = useSelector(selectShowCookieConsentBanner);
  const isMobile = useSelector(selectIsMobile);
  const isOneClick = useSelector(selectOneClick);
  const isCookieFeatureOn = useFeatureIsOn("cookie-preferences");

  useEffect(() => {
    let isMounted = true;
    if (isMounted && showCookieConsentBanner && !isCookieFeatureOn) {
      setShowCookieBanner(true);
    }

    return () => {
      isMounted = false;
    };
  }, [showCookieConsentBanner, isCookieFeatureOn]);

  return (
    <>
      <div className="min-h-screen">
        <div className="max-w-screen-desktop mx-auto">
          <div className="flex lg:flex-row flex-col lg:mb-8">
            <div className="flex flex-col lg:pe-4 w-full xl:w-8/12 lg:w-7/12">
              <MobileLogo />
              <HeroImage path={image?.original.path} title={title} />
              <CampaignTitle title={title} />
              <BodyContent content={content} />
              {sources.length > 0 && (
                <SourceList sourceList={sources} lang={language} />
              )}
              <MobileCTA
                cta={cta || t("button_donate_now")}
                setModalOpen={setModalOpen}
                loading={loading}
              />
              {isMobile && (
                <>
                  {showCookieBanner && showCookieConsentBanner && (
                    <CookieConsent
                      closeCookieConsentBanner={() =>
                        dispatch(closeCookieConsentBanner())
                      }
                    />
                  )}
                  <PopupModal
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    isOneClick={isOneClick}
                  >
                    <Donation
                      isMobile={true}
                      openModal={setModalOpen}
                      pageIsLoading={loading}
                    />
                  </PopupModal>
                </>
              )}
            </div>
            <div className="rounded-sm border border-gray-100 xl:px-3 lg:px-0 px-3 py-8 me-2 shadow-md h-full xl:w-4/12 lg:w-5/12 w-full hidden lg:block">
              {!isMobile && <Donation pageIsLoading={loading} />}
            </div>
          </div>
        </div>
      </div>
      {!isMobile && (
        <CampaignersPopup publish_status={publish_status} lang={language} />
      )}
    </>
  );
};

export default Fundraiser;
